import ApiService from '@/services/api.service'
import URLS from '@/config/urls'

import Format from '@/services/mixins/format.mixins'

export default {
  methods: {
    downloadVpnProfile (name) {
      return ApiService.query(URLS.API.LABS.VPN.DOWNLOAD_PROFILE)
        .then((response) => {
          if (response.status !== 200 || response.data.status === 'ko') throw new Error()
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `vpn_${Format.methods.slugify(name)}.ovpn`) // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(e => { console.log(e); this.showErrorToast(this.$t('VPN.ERRORS.download')) })
        .then(_ => { this.loading = false })
    }
  }
}
