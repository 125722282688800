<template>
  <header>
    <div class="main-container">
      <router-link :to="{name: 'home'}" class="no-style main-logo">
          <div class="logo" ></div>
      </router-link>

      <div class="action-container">
        <div class="d-none d-md-block">
          <b-button variant="transparent" style="color: white;" class="mr-1 p-relative" @click="downloadVpnProfile(user.name)" v-b-tooltip.hover.bottom="$t('TERMS.vpn')" v-if="canSeeVpn">
            <b-icon icon="diagram3-fill" />
            <div class="vpn-indicator" :class="{ connected: connectedToVpn, disconnected: !connectedToVpn }">
            </div>
          </b-button>
          <b-button variant="transparent" style="color: white;" class="mr-1" @click="goToTerminal" v-b-tooltip.hover.bottom="$t('TERMS.terminal')" v-if="canSeeUserConsole"><b-icon icon="terminal-fill" /> </b-button>
          <b-button variant="transparent" style="color: white;" class="mr-1" :to="{ name: 'faqs' }" v-b-tooltip.hover.bottom="$t('GENERAL.faqs')"><b-icon icon="question-circle-fill" /> </b-button>
          <b-button variant="transparent" style="color: white;" class="mr-1" @click="$refs.reportIssue.$refs.modal.show()" v-b-tooltip.hover.bottom="$t('ACTIONS.report_issue')"><b-icon icon="bug-fill" /> </b-button>

          <div class="user-info" v-b-toggle.sidebar-profile style="outline: none; display: inline-block; color: white;">
            <span>{{ $t('GENERAL.hello') }} {{ name }}</span>
            <font-awesome-icon class="ml-2" :icon="['fas', 'chevron-down']" color="white" />
            <img class="profile ml-small mr-small" :src="profilePic" alt="player-profile-pic">
          </div>
        </div>

        <div class="d-block d-md-none">
          <b-button v-b-toggle.sidebar-profile variant="transparent" style="color: white;" class="mr-1" ><b-icon icon="justify" scale="2" /> </b-button>
        </div>
      </div>
    </div>

    <ReportIssue ref="reportIssue" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastsMixin from '@/services/mixins/toasts.mixins'
import VpnMixin from '@/services/mixins/vpn.mixins'

import ReportIssue from '@/components/modals/ReportIssue'
import mapper from '@/services/mixins/mapper.mixins'

export default {
  name: 'my-header',
  mixins: [mapper, ToastsMixin, VpnMixin],
  components: { ReportIssue },
  data () {
    return {
      sections: [
        {
          name: 'Academia',
          routeName: 'categories'
        },
        {
          name: 'Objetivos',
          routeName: 'objectives'
        },
        {
          name: 'Labs',
          routeName: 'labs'
        },
        {
          name: 'Lista de píldoras',
          routeName: 'pills'
        },
        {
          name: 'Carrera técnica',
          routeName: 'careers'
        }
      ]
    }
  },
  methods: {
    goToTerminal () {
      this.$router.push({ name: 'terminal' })
    },
    goToVpn () {
      this.$router.push({ name: 'profileVpn' })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      canSeeUserConsole: 'config/canSeeUserConsole',
      canSeeVpn: 'config/canSeeVpn',
      dummyProfilePic: 'staticInfo/getDummyProfilePic',
      connectedToVpn: 'config/connectedToVpn'
    }),
    name () { return this.user.name },
    email () { return this.user.email },
    hasProfilePic () { return typeof this.user.profile_pic !== 'undefined' && this.user.profile_pic !== '' },
    profilePic () {
      return this.hasProfilePic ? this.user.profile_pic : this.dummyProfilePic
    }
  }
}
</script>

<style lang="scss" scoped>
header {
    position: relative;
    height: $header-height;
    // background: $header-background;
    padding: $space-medium;
    text-align: left;
    z-index: 1;
    display: flex;

    .main-container {
      display: flex;
      justify-content: space-between;

      .logo {
        height: 80px;
        width: 250px;
        background-image: var(--logo);
        filter: brightness(0) invert(1);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left;
      }
    }

    img {
      height: calc(#{$header-height} - 30px);
      width: calc(#{$header-height} - 30px);
      object-fit: cover;
    }
}

@media (max-width: $mobile-breakpoint) {
  header {

      .main-container {

        .logo {
          height: 80px;
          width: 50px;
          background-image: var(--logo-small-white);
        }
      }

      .action-container {
        display: flex;
        align-items: center;
        margin-top: 4%;
      }
  }
}

// header {
//     position: relative;
//     height: $header-height;
//     background: $header-background;
//     padding: $space-medium;
//     text-align: right;
//     z-index: 100000;

//     .profile {
//         width: $image-size-big;
//         height: $image-size-big;
//         object-fit: cover;
//     }

//     .main-logo {
//         left: $space-big;

//         img {
//             height: calc(100% - #{$space-big} * 2);
//             margin: $space-big 12px;
//         }
//     }

//     .user-info {
//         right: $space-big;
//         color: $text-color;

//         .profile {
//             height: $image-size-small;
//             width: $image-size-small;
//             object-fit: cover;
//         }

//         & > * {
//             line-height: $header-height;
//         }
//     }

//     & > * {
//         // position: absolute;
//         // top: 0;
//         // bottom: 0;
//         display: inline-block;
//         vertical-align: middle;
//     }

//     .sections {
//       display: inline-block;
//       vertical-align: middle;
//       height: 100%;

//       ul {
//         display: block;
//         height: 100%;

//         .router-link-active * {
//           color: $primary !important;
//         }

//         li {
//           height: 100%;
//           cursor: pointer;
//           color: $text-color;
//           list-style: none;
//           display: inline-block;
//           line-height: $header-height;
//           padding: 0 32px;
//           text-align: center;
//           font-weight: 500;

//           &:hover {
//             color: $grey;
//           }

//           &:first-child {
//             margin-left: -32px;
//           }
//         }
//       }
//     }
// }

</style>
