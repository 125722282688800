<template>
  <div>
    <div id="tabs-nav" class="mt-5">

      <router-link :to="{name: section.routeName, params: { name: section.name } }" v-for="section in sections" v-bind:key="section.name" class="no-style d-xl-block d-lg-block d-md-block d-none">
        <font-awesome-icon :icon="['fas', section.icon]" class="mr-2" style="color: #3F4254;" />
        <span class="title">{{ $t(section.name) }}</span>
      </router-link>

    </div>

    <div class="section-selector text-center d-xl-none d-lg-none d-md-none d-block">
      <div class="current-section clickable" @click="show = !show">
        <h5>
          <font-awesome-icon :icon="['fas', currentSection.icon]" class="mr-2" style="color: #3F4254;" />
          {{ $t(currentSection.name) }}</h5>
      </div>
      <div v-if="show">
        <div @click="moveToSection(index)" v-for="(section, index) in sections" v-bind:key="section.name" class="no-style clickable">
          <h6 class="title">
            <font-awesome-icon :icon="['fas', section.icon]" class="mr-2" style="color: #3F4254;" />
            {{ $t(section.name) }}
          </h6><br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      show: false,
      current: 'GENERAL.home',
      currentPosition: 0,
      sections: [
        {
          name: 'GENERAL.home',
          description: '',
          icon: 'home',
          routeName: 'home'
        },
        {
          name: 'GENERAL.academy',
          description: '',
          icon: 'graduation-cap',
          routeName: 'categories'
        },
        {
          name: 'PAGES.careers',
          description: '',
          icon: 'briefcase',
          routeName: 'careers'
        },
        {
          name: 'PAGES.labs',
          description: 'LABS.tab_description',
          icon: 'flask',
          routeName: 'labs'
        },
        {
          name: 'PAGES.objectives',
          description: '',
          icon: 'bullseye',
          routeName: 'objectives'
        }
      ]
    }
  },
  watch: {
    routeName (current, past) {
      // Home
      if (this.routeName.startsWith('home')) return this.changeCurrentSection(0)

      // Academy
      if (this.routeName.startsWith('categor')) return this.changeCurrentSection(1)
      if (this.routeName.startsWith('workRole')) return this.changeCurrentSection(1)
      if (this.routeName.startsWith('courseDetail')) return this.changeCurrentSection(1)
      if (this.routeName === 'careerDetail') return this.changeCurrentSection(1)

      // Objectives
      if (this.routeName.startsWith('objective')) return this.changeCurrentSection(2)

      // Labs
      if (this.routeName.startsWith('labs')) return this.changeCurrentSection(3)
      if (this.routeName.startsWith('machine')) return this.changeCurrentSection(3)

      // Pills
      if (this.routeName.startsWith('pill')) return this.changeCurrentSection(4)
    }
  },
  methods: {
    changeCurrentSection (index) {
      this.currentPosition = index
      this.show = false
    },
    moveToSection (index) {
      this.changeCurrentSection(index)
      this.$router.push({ name: this.currentSection.routeName })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      canSeeCareers: 'config/canSeeCareers'
    }),
    currentSection () { return typeof this.sections[this.currentPosition] !== 'undefined' ? this.sections[this.currentPosition] : { icon: 'chart-pie' } },
    routeName () { return this.$route.name }
  },
  created () {
    if (!this.canSeeCareers) this.sections.splice(2, 1)
  }
}
</script>

<style lang="scss" scoped>
#tabs-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F0F3F5;
  border-radius: 8px;

  .router-link-active {
    background-color: white !important;
  }

  & > * {
    flex: 1 1 0px;
    padding: 0.75rem !important;
    text-align: center;
    cursor: pointer;
    color: #B5B5C3;

    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }

    .title {
      color: #3F4254;
      font-weight: bold;
    }

    .subtitle {
      font-size: 0.7rem;
    }

    &.active {
      background-color: white;
    }
  }
}

// Responsive navigation header
.section-selector {
  border-bottom: 1px solid $grey-medium;

  .title {
    margin-bottom: -0.5rem;
  }

  .current-section {
    padding: 1rem;
  }
}
</style>
