<template>
  <b-sidebar id="sidebar-profile" no-header right backdrop shadow bg-variant="dark" text-variant="light" style="z-index: 1001;">
    <template v-slot:default="{ hide }">
      <div class="p-3">
        <div class="t-center mb-medium">
          <img id="profile-pic-sidebar" class="round profile mb-medium" :src="profilePic" alt="profile-pic" @click="hide">
          <h6 id="sidebar-no-header-title" style="word-break: break-all;">{{ email }}</h6>
        </div>

        <div class="my-3 t-center" v-if="currentBlockedMachine">
          <h5>{{ $t('GENERAL.currentBlockedMachine') }}</h5>
          <h6>{{ currentBlockedMachine.name }}</h6>
          <UnlockLabMachine only-icon :machine-id="currentBlockedMachine.id" @success="machineUnlockSuccess" @error="machineUnlockError" class="mr-2" />
        </div>

        <div class="t-center mt-5">
          <h5>{{ $t('GENERAL.quick_actions') }}</h5>
        </div>

        <div class="d-block d-md-none mb-5 text-center">
          <b-button variant="transparent" style="color: white;" class="mr-1 p-relative" @click="downloadVpnProfile(user.name)" v-b-tooltip.hover.bottom="$t('TERMS.vpn')" v-if="canSeeVpn">
            <b-icon icon="diagram3-fill" />
            <div class="vpn-indicator" :class="{ connected: connectedToVpn, disconnected: !connectedToVpn }">
            </div>
          </b-button>
          <b-button variant="transparent" style="color: white;" class="mr-1" @click="goToTerminal" v-b-tooltip.hover.bottom="$t('TERMS.terminal')" v-if="canSeeUserConsole"><b-icon icon="terminal-fill" /> </b-button>
          <b-button variant="transparent" style="color: white;" class="mr-1" :to="{ name: 'faqs' }" v-b-tooltip.hover.bottom="$t('GENERAL.faqs')"><b-icon icon="question-circle-fill" /> </b-button>
          <b-button variant="transparent" style="color: white;" class="mr-1" @click="$refs.reportIssue.$refs.modal.show()" v-b-tooltip.hover.bottom="$t('ACTIONS.report_issue')"><b-icon icon="bug-fill" /> </b-button>
        </div>

        <b-button variant="primary" block @click="$router.push({ name: 'profileHome' }); hide()">{{ $t('GENERAL.go_to_profile') }}</b-button>
        <b-button class="mt-2" variant="danger" block @click="logout()">{{ $t('AUTH.log_out') }}</b-button>
      </div>

      <ReportIssue ref="reportIssue" />
    </template>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ReportIssue from '@/components/modals/ReportIssue'
import UnlockLabMachine from '@/components/labs/UnlockLabMachine'

import mapper from '@/services/mixins/mapper.mixins'
import ToastMixin from '@/services/mixins/toasts.mixins'
import VpnMixin from '@/services/mixins/vpn.mixins'

export default {
  name: 'UserSidebar',
  components: { ReportIssue, UnlockLabMachine },
  mixins: [mapper, ToastMixin, VpnMixin],
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),
    goToTerminal () {
      this.$router.push({ name: 'terminal' })
    },
    logout () {
      this.logoutAction()
        .then(_ => this.$router.push({ name: 'login' }))
    },

    machineUnlockSuccess () {
      this.showSuccessToast(this.$t('LAB_MACHINE.ACTIONS.unlock_machine_success'))
    },
    machineUnlockError () {
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      canSeeUserConsole: 'config/canSeeUserConsole',
      canSeeVpn: 'config/canSeeVpn',
      dummyProfilePic: 'staticInfo/getDummyProfilePic',
      connectedToVpn: 'config/connectedToVpn'
    }),
    // Sidebar
    name () { return this.user.name },
    email () { return this.user.email },
    hasProfilePic () { return typeof this.user.profile_pic !== 'undefined' && this.user.profile_pic !== null && this.user.profile_pic !== '' },
    profilePic () {
      return this.hasProfilePic ? this.user.profile_pic : this.dummyProfilePic
    },

    currentBlockedMachine () {
      return this.user.current_blocked_machine ? this.user.current_blocked_machine : undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
    height: $image-size-small;
    width: $image-size-small;
    object-fit: cover;
    border-radius: 8px;
}

#profile-pic-sidebar {
    height: $image-size-big;
    width: $image-size-big;
}
</style>
