<template>
  <div class="background-gradient-container flex flex-column h-100" >
    <Header></Header>

    <div id="content" class="main-container-wrapper">
      <div class="main-container mb-5 pb-5">
        <NavigationHeader />
        <transition name="fade-short" mode="out-in">
          <router-view :key="$route.params.id"/>
        </transition>
      </div>
    </div>

    <UserSidebar />

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NavigationHeader from '@/components/NavigationHeader'
import UserSidebar from '@/components/UserSidebar'

export default {
  name: 'BaseLayout',
  components: { Header, NavigationHeader, UserSidebar, Footer }
}
</script>

<style lang="scss" scoped>

.main-container-wrapper {
  flex: 1;

  .main-container {
    background-color: white;
    border-radius: 8px;
  }
}

footer {
  position: relative;
}
</style>
