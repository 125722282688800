<template>
  <div>
    <b-modal
      ref="modal"
      :title="$t('ACTIONS.report_issue')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="isFormFieldValid('message')"
          :label="$t('TERMS.issue')"
          label-for="name-input"
        >
        <b-form-textarea
          id="textarea"
          v-model="$v.form.message.$model"
          :state="isFormFieldValid('message')"
          :placeholder="$t('ISSUES.placeholder')"
          rows="3"
          max-rows="3"
          required
          style="overflow:auto;"
          no-resize
          aria-describedby='textarea-live-feedback'
        ></b-form-textarea>
          <b-form-invalid-feedback id='textarea-live-feedback'>
            <!-- TODO Translate the error -->
            <div v-if="$v.form.message.required && !$v.form.message.minLength">{{ $t('FORMS.length_requirement', { n: 3 }) }}</div>
            <div v-if="$v.form.message.required && !$v.form.message.serverSaidOK">{{ serverErrors.message }}</div>
            <div v-if="$v.form.message.required && !$v.form.message.maxLength">{{ $t('FORMS.max_length_requirement', { n: 500 }) }}</div>
            <div v-if="!$v.form.message.required">{{ $t('FORMS.required') }}</div>

          </b-form-invalid-feedback>
        </b-form-group>
      </form>
      <loading :loading="loading"/>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastsMixin from '@/services/mixins/toasts.mixins'

import { validationMixin } from 'vuelidate'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'ReportIssueModal',
  mixins: [ToastsMixin, validationMixin],
  data () {
    return {
      form: {
        message: ''
      },
      serverErrors: {
        message: ''
      }
    }
  },
  validations: {
    form: {
      message: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(500),
        serverSaidOK (value) {
          return this.serverErrors.message === ''
        }
      }
    }
  },
  methods: {
    ...mapActions({
      submitIssue: 'user/submitIssue'
    }),
    isFormFieldValid (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    isFormValid () { return !this.$v.form.$anyError },
    mapErrors (errorsObj) {
      Object.keys(errorsObj).forEach(err => {
        if (typeof errorsObj[err].errors !== 'undefined') {
          this.serverErrors[err] = errorsObj[err].errors[0]
        }
      })
    },
    resetModal () {
      this.form.message = ''
      this.serverErrors.message = ''
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      this.$v.form.$touch()

      // Exit when the form isn't valid
      if (!this.isFormValid()) {
        return
      }

      this.submitIssue({ message: this.$v.form.message.$model })
        .then(_ => {
          this.showSuccessToast(this.$t('ISSUES.success'))
          this.$emit('success')

          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
        .catch(_ => { this.showErrorToast(this.$t('ISSUES.error')) })
    }
  },
  computed: {
    ...mapGetters({
      loading: 'user/isLoading'
    })
  }
}
</script>

<style scoped>
  textarea::-webkit-scrollbar {
    display: none;
  }
</style>
